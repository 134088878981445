export interface User {
  ssoId: string;
  firstName: string;
  lastName: string;
  email: string;
  contactNumber?: string;
  role?: string;
  userLevel: number;
  permissions?: any[];
  parentAccount?: string;
  state?: any;
  emailConfirmed?: boolean;
}

export interface UserLite {
  email: string | null;
  id: string | null;
  lastActivity: string | null;
  name: string | null;
  status: "ACTIVE" | "INACTIVE" | null;
  userAccess: AccountLevel[];
  validated: boolean;
}

export enum AccountLevel {
    Group = "GROUP",
    Company = "COMPANY",
    Account = "ACCOUNT",
    SubAccount = "SUB_ACCOUNT",
    Asset = "ASSET",
}

export const accountLevelToString: { [key in AccountLevel]: string } = {
    [AccountLevel.Group]: "Group",
    [AccountLevel.Company]: "Company",
    [AccountLevel.Account]: "Account",
    [AccountLevel.SubAccount]: "Sub Account",
    [AccountLevel.Asset]: "Individual",
}

export interface GetUsersParams {
  page: number;
  size: number;
  accountId: string;
}

export interface GetUsersResp {
  content: UserLite[];
  totalElements: number;
}

export interface ExistingUser {
  email: string;
  id: string;
  firstName: string;
  lastName: string;
  contactNumber?: string;
}

export interface GetExistingUserResp {
  id: string;
  givenName: string;
  familyName: string;
  email: string;
  phoneNumber?: string;
}

export interface CreateUserParams {
  accountId: string;
  contactNumber?: string | null;
  email: string;
  firstName: string;
  lastName: string;
}

export interface AssignUserParams {
  accountId: string;
  userId: string;
}

export interface GetHubUserJwtResp {
  access_token: string;
  sso_url: string;
  hub_url: string;
}

export interface AuthConfig {
  tokenIssuerUrl: string;
  customerPortalUrl: string;
  partnerPortalClientId: string;
}
